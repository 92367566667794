import React from 'react';

const ZymifyLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 60" className="w-full h-auto">
    <defs>
      <linearGradient id="awsGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{stopColor:"#FF9900", stopOpacity:1}} />
        <stop offset="100%" style={{stopColor:"#FFC300", stopOpacity:1}} />
      </linearGradient>
    </defs>
    
    <rect x="0" y="0" width="300" height="60" rx="10" ry="10" fill="url(#awsGradient)" />
    
    <path d="M30 20 L50 20 L60 30 L60 40 L50 40 L30 40 Z" fill="#2D8CFF" />
    <circle cx="40" cy="30" r="5" fill="#ffffff" />
    
    <text x="80" y="40" fontFamily="Arial, sans-serif" fontSize="28" fontWeight="bold" fill="#232F3E">
      Zymify
    </text>
    
    <path d="M240 35 Q255 20 270 35" stroke="#232F3E" strokeWidth="3" fill="none" />
  </svg>
);

export default ZymifyLogo;